import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const Impressum = () => {
  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/imprint")
    }
  }, [])

  return (
    <React.Fragment>
      <PageTitle title={"Impressum"} />
      <Layout>
        <div className="imprint-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Impressum</a>
              </li>
            </ul>
          </div>
          <div className="imprint">
            <h1>Impressum</h1>
            <div className="imprint__block">
              <div className="imprint__block--info">
                Verantwortlich für den Inhalt: <br />
                Cyber Trust Services GmbH <br />
                Wienerbergstraße 11 / 12A <br />
                A-1100 Wien <br />
                Mail:{" "}
                <a href="mailto:office@cyber-trust.at">office@cyber-trust.at</a>
                <br />
                Firmenbuchnummer FN 535636a <br />
                Geschäftsführer: Dr. Thomas Stubbings, MBA <br />
              </div>
            </div>
            <div className="text">
              <p>
                Haftungsausschluss: Trotz der sorgfältigen Recherche und
                Verifizierung können leider Fehler in den Inhalten dieser
                Webseite nicht ausgeschlossen werden. Hinweise und Korrekturen
                werden von office@cyber-trust.at gerne entgegengenommen. Für
                eventuelle Schäden oder Konsequenzen aus der direkten oder
                indirekten Nutzung der Inhalte übernimmt CTS Cyber Trust
                Services GmbH keinerlei Haftung.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Impressum
